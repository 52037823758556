import axios from "axios";
import React, { useEffect, useState } from "react";
import swirls from "../../utils/swirls";
import TypedText from "../common/TypedText";
import RotatingLinesLoader from "../common/RotatingLinesLoader";
import styles from "./ContactForm.module.css";
import { toast } from "react-toastify";

import shakespeareLogo from "../../assets/shakespeare_logo.png";

const ContactForm = () => {
	const [inputs, setInputs] = useState({});
	const [tncChecked, setTncChecked] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [showThankYou, setShowThankYou] = useState(false);

	let nextStep, dotValue;

	const speed = 0.012; // 0 < speed < 1
	const velocity = 4; // velocity > 0

	const elastic = () => {
		const elasticScale =
			(Math.pow(2, -25 * dotValue) *
				Math.sin((dotValue * 10 - 0.75) * velocity) +
				1) *
			100;
		let download_button = document.getElementById(styles.submit_button);
		if (!download_button) return;
		download_button.style.transform = "scale(" + elasticScale + "%)";

		dotValue += speed;
		if (dotValue <= 1) {
			nextStep = requestAnimationFrame(elastic);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setInputs({ ...inputs, [name]: value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		dotValue = 0; // Reset position
		cancelAnimationFrame(nextStep);
		elastic();

		setIsSubmitting(true);

		inputs.currency = inputs.currency || "INR";

		try {
			if (
				!inputs.name ||
				!inputs.request ||
				!inputs.company ||
				!inputs.email ||
				!inputs.description ||
				!inputs.budget
			) {
				toast.error("Please fill all the fields!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			} else if (!tncChecked) {
				toast.error("Please accept the terms and conditions!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			} else if (
				!inputs.email.match("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$")
			) {
				toast.error("Please enter a valid email address!", {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					draggable: true,
					pauseOnHover: false,
					theme: "colored",
				});
				setIsSubmitting(false);
				return;
			}

			await axios.post("/leads/business", inputs, {
				headers: {
					"x-auth-token": process.env.REACT_APP_MAIL_TOKEN,
				},
			});

			setInputs({});

			toast.success("Thank you for your interest!", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});

			setShowThankYou(true);
			setIsSubmitting(false);
		} catch (err) {
			toast.error("Something went wrong, please try again later.", {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				draggable: true,
				pauseOnHover: false,
				theme: "colored",
			});
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		if (window.matchMedia("(min-width: 768px)").matches) {
			swirls.setup(styles.content__canvas);
			return () => {
				swirls.cleanup();
			};
		}
	}, []);

	return (
		<div className={styles.body}>
			<a href="https://shakespeare.vision">
				<img
					src={shakespeareLogo}
					alt="Shakespeare Logo"
					className={styles.logo}
				/>
			</a>
			<div className={styles.content__canvas} />
			<div
				className={styles.form_wrapper}
				style={
					showThankYou
						? {
								position: "absolute",
								top: "50%",
								left: "50%",
								width: "80%",
								transform: "translateX(-50%) translateY(-50%)",
						  }
						: {}
				}
			>
				{!showThankYou && (
					<div className={styles.typed_block}>
						<h2>Transform your</h2>
						<TypedText
							strings={["Restaurant", "Business", "Idea"]}
							style={{
								color: "#EEEE6E",
								fontSize: "32px",
								margin: window.matchMedia("(max-width: 800px)").matches
									? "0"
									: "0 8px",
								padding: "0",
								textShadow: "0 1px 6px white",
								fontFamily: "Poppins, sans-serif",
							}}
							loop={true}
							typeSpeed={40}
							backSpeed={40}
							backDelay={1000}
						/>
						<h2>into Metaverse</h2>
					</div>
				)}
				{showThankYou ? (
					<div className={styles.thank_you_block}>
						<h1>Thank you for letting us know your requirement!</h1>
						<h2>You will receive a confirmation email shortly.</h2>
					</div>
				) : (
					<div className={styles.form_block}>
						<div className={styles.input_flexbox}>
							My name is
							<input
								name="name"
								value={inputs.name || ""}
								className={styles.input_field}
								placeholder="Harsha"
								onChange={handleChange}
							/>
						</div>
						<div className={styles.input_flexbox}>
							I want you to
							<input
								name="request"
								value={inputs.request || ""}
								className={styles.input_field}
								placeholder="create Metaverse bot"
								onChange={handleChange}
							/>
						</div>
						<div className={styles.input_flexbox}>
							My Company / Restaurant is called
							<input
								name="company"
								value={inputs.company || ""}
								className={styles.input_field}
								placeholder="Maredumilli"
								onChange={handleChange}
							/>
						</div>
						<div className={styles.input_flexbox}>
							I've got a budget of
							<input
								name="budget"
								type="number"
								min={0}
								value={inputs.budget || ""}
								className={styles.input_field}
								onChange={handleChange}
							/>
							<select
								name="currency"
								className={styles.input_field}
								style={{ marginRight: "8px" }}
								onChange={handleChange}
							>
								<option>INR</option>
								<option>USD</option>
							</select>
							to make the project real
						</div>
						<div className={styles.input_flexbox}>
							Here is a short description of what the Company is about, and why
							I want you to work with me on it:
						</div>
						<div className={styles.input_flexbox}>
							<textarea
								name="description"
								value={inputs.description || ""}
								className={styles.input_field}
								style={{
									resize: "none",
									width: "95%",
									height: "100px",
									marginRight: "8px",
								}}
								placeholder="My company is... and I want you to work with me because..."
								onChange={handleChange}
							/>
						</div>
						<div className={styles.input_flexbox}>
							Please contact me at
							<input
								name="email"
								value={inputs.email || ""}
								type="email"
								className={styles.input_field}
								placeholder="youremail@mail.com"
								onChange={handleChange}
							/>
						</div>
						<div className={styles.input_flexbox}>
							<input
								type="checkbox"
								className={styles.input_field}
								checked={tncChecked}
								onChange={(e) => setTncChecked(e.target.checked)}
								style={{ cursor: "pointer" }}
							/>
							I hereby accept all
							<a
								className={styles.tnc}
								href="https://shakespeare.vision/terms"
								target="_blank"
							>
								terms and conditions
							</a>
						</div>
						<div className={styles.input_flexbox} style={{ fontSize: "20px" }}>
							Thank You!
						</div>
						<div className={styles.input_flexbox} style={{ fontSize: "20px" }}>
							<button
								id={styles.submit_button}
								className={styles.submit_button}
								onClick={handleSubmit}
							>
								{isSubmitting ? (
									<div
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											flexDirection: "row",
										}}
									>
										<RotatingLinesLoader width="20px" strokeColor="black" />
										&nbsp; Please Wait...
									</div>
								) : (
									"Submit"
								)}
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ContactForm;
